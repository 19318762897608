/* ==========================================================================
   Navbar Style
   ========================================================================== */

.navbar{
    background-color: var(--white);
    height: var(--navbar-height);
}
.logo-menu a{
    font-size: 20px; 
    color: var(--black);
}
.menu-bg{
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    padding: 5px;

    .menu-button{
        top: 0;
        &:hover{
            cursor: pointer;
            color: var(--primary);
        }
    } 

    .logo-menu a{
        color: var(--primary);
    }

    .navbar-nav .nav-link{
        color: var(--black)!important;
        &:hover{
            color: var(--black)!important;
        }
    }
    .navbar-nav .active{
        color: var(--black)!important;
    }
}
.navbar-expand-md .navbar-nav .nav-link{
    color: var(--black);
    font-size: 20px;
    padding: 5px 0px;
    margin-left: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    position: relative;
    &:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: var(--black);
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        transition: 0.3s;
    }
}
.navbar-expand-md .navbar-nav .active::before,
.navbar-expand-md .navbar-nav .nav-link:hover::before{
    width: 100%;
}
.navbar-brand{
    color: var(--black);
    padding-bottom: 18px;

    img {
        padding-top: 10px;
        width: 230px;
    }
}
.navbar-expand-md .navbar-nav .nav-link:focus,
.navbar-expand-md .navbar-nav .nav-link:hover,
.navbar-expand-md .navbar-nav .nav-link:hover:before{
    color: var(--black);
}
.navbar-expand-md .navbar-nav .active > .nav-link,
.navbar-expand-md .navbar-nav .nav-link.active,
.navbar-expand-md .navbar-nav .nav-link.active:before,
.navbar-expand-md .navbar-nav .nav-link.open, 
.navbar-expand-md .navbar-nav .open > .nav-link{
    color: var(--black);
}
.navbar-expand-md .navbar-toggler{
    background: transparent;
    border: 1px solid var(--black);
    border-radius: 3px;
    margin: 5px;
    cursor: pointer;
    float: right;
    i{
    color: var(--black)!important;
    }
    &:focus{
    outline: none;
    }
}
