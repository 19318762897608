/* ==========================================================================
   Footer Style
   ========================================================================== */
 
.footer-content {
    padding-top:80px;
    box-shadow: 0px -3px 6px #00000010;
    background-color: var(--white);

    .logo-footer {
        width: 200px;
    }

    .logo-social-network {
        margin-right: 20px;
        width: 40px;
    }

    .title {
        font-size: 20px;
        margin-bottom: 20px !important;
        font-weight: 700;
    }

    p, a {
        font-size: 14px;
        font-weight: 400;
    }

    ul li {
        margin-bottom: 10px;
    }
}
 