:root {
    /** Colors */
    --primary: #00aa9e;
    --primary-light:#7ec082;
    --second : #00aa9e;
    --dark: #091720;
    --black: #000000;
    --white: #FFFFFF;

    /** Header height */    
    --navbar-height: 100px;
    --screen-height: calc(100vh - var(--navbar-height));

    --border-radius: 15px;
    // --box-shadow: 0px 3px 60px 10px rgba(0, 0, 0, 0.1);
    --box-shadow: 0px 1px 15px 4px rgba(0, 0, 0, 0.1);
}

// /** Colors */
// var(--primary) : #379CE9;
// var(--primary)-light: #73B7EB;
// var(--second) : #FE7F2D;
// var(--black): #000000;
// var(--white): #FFFFFF;

/** Header height */
// $navbar-height: 100px;
// $screen-height: calc(100vh - #{$navbar-height});
