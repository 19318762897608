/* ==========================================================================
Subscribe Style
========================================================================== */
#subscribe{
    margin: 100px 0px;
    background-image: -moz-linear-gradient( 0deg, var(--primary) 0%, var(--primary-light) 100%);
    background-image: -webkit-linear-gradient( 0deg, var(--primary) 0%, var(--primary-light) 100%);
    position: relative;
}
.subscribe-form{
    margin-bottom: 15px;
    .sub-title{
        margin-bottom: 30px;
        h3{
            color: #fff;
            font-size: 30px;
            margin-bottom: 20px;
            position: relative;
            font-weight: 600;
        }
        p{
            font-size: 20px;
            color: #fff;
        }
    }
    .form-wrapper{
        .form-group{
            margin-bottom: 0px;
        }
        .form-control{
            display: block;
            width: 100%;
            height: 65px;
            text-align: left;
            font-size: 20px;
            box-shadow: none;
            margin-bottom: 0;
            color: #fff;
            font-weight: 500;
            background: transparent;
            border: 2px solid #fff;
            border-radius: 50px;
            background-image: none;
            background-clip: padding-box;
            padding-left: 20px;
        }
        .form-control:focus{
            color: #ffffff;
            border-color: #fff;
            box-shadow: none;
            outline: 0;
        }
        .btn-search{
            padding: 0px 25px;
            top: 6px;
            right: 22px;
            z-index: 2;
            font-weight: 600;
            background: #fff;
            color: var(--second);
            height: 52px;
            text-align: center;
            position: absolute;
        }
        input::placeholder {
            color: #fff;
            font-weight: 500;
        }
    }
    .form-submit{
        .btn{
            width: 100%;
            padding: 13px 30px;
        }
    }
}
#subscribe{
    .sub-item-box{
        padding: 30px;
        margin-bottom: 30px;
        border-radius: 4px;
        background: #fff;
        .icon-box{
            float: left;
            i{
                font-size: 30px;
                color: var(--primary);
            }
        }
        .text-box{
            margin-left: 60px;
            h4{
                font-size: 16px;
                font-weight: 500;
            }
            p{
                margin-bottom: 0;
            }
        }
    }
    .sub-item-box:last-child{
        margin-bottom: 0;
    }
}

