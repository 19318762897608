/* ==========================================================================
IMPORT GOOGLE FONTS
========================================================================== */
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto');

@font-face {
    font-family: 'Poppins';
    src:  url('../fonts/Poppins-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src:  url('../fonts/Poppins-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}