:root {
    --border-radius-radio: 25px;
    --border-width: 5px;
}

.radio-slider {
    perspective: 800px;
}

.toggle {
    position: relative;
    border: solid var(--border-width) var(--primary);
    border-radius: var(--border-radius-radio);
    transition: transform cubic-bezier(0, 0, 0.30, 2) .4s;
    transform-style: preserve-3d;
    perspective: 800px;
}

.toggle>input[type="radio"] {
    display: none;
}

.toggle>#choice1:checked~#flap {
    transform: rotateY(-180deg);
}

.toggle>#choice1:checked~#flap>.content {
    transform: rotateY(-180deg);
}

.toggle>#choice2:checked~#flap {
    transform: rotateY(0deg);
}

.toggle>label {
    display: inline-block;
    width: calc(80vw / 2);
    max-width: 170px;
    padding: 7px 5px 0;
    font-size: var(--font-size);
    text-align: center;
    color: var(--primary);
    cursor: pointer;
}

.toggle>label,
.toggle>#flap {
    text-transform: capitalize;
}

.toggle>#flap {
    position: absolute;
    top: calc( 0px - var(--border-width));
    left: 50%;
    height: calc(100% + var(--border-width) * 2);
    width: 51%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size);
    background-color: var(--primary);
    border-top-right-radius: var(--border-radius-radio);
    border-bottom-right-radius: var(--border-radius-radio);
    transform-style: preserve-3d;
    transform-origin: left;
    transition: transform cubic-bezier(0.4, 0, 0.2, 1) .5s;
}

.toggle>#flap>.content {
    color: var(--white);
    transition: transform 0s linear .25s;
    transform-style: preserve-3d;
    text-transform: uppercase;
    font-weight: 700;
}