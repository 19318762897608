/* ==========================================================================
GENERAL
========================================================================== */
body{
    font-family: 'Poppins','Roboto', sans-serif;
    color: var(--black);
    font-size: 16px;
    font-weight: 400;
    background: #fff;
    overflow-x: hidden;
}
html{
    overflow-x: hidden;
}
p{
    margin-bottom: 0;
}
a:hover, a:focus {
    color: var(--black);
}
a{
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 700;
    color: var(--black);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
h1,h2,h3,h4,h5, .text-h1, .text-h2, .text-h3, .text-h4, .text-h5{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 0.5rem;
}
h1, .text-h1{
    font-size: 50px; 
    font-weight: 900;
}
h2, .text-h2{
    font-size: 40px;
}
h3, .text-h3{
    font-size: 30px;  
}
h4, .text-h4{
    font-size: 25px;
    font-weight: 500;
}
h5, .text-h5{
    font-size: 20px;
    font-weight: 400;
}
ul{
    margin: 0;
    padding: 0;
    li{
    list-style: none;
    }
}
a:hover,
a:focus{
    text-decoration: none;
    outline: none;
}
a:not([href]):not([tabindex]){
    color: #fff;
}
a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover{
    color: #fff;
}
a.text-primary:hover {
    color: var(--primary) !important;
}
.bg-transparent{
    background: transparent!important;
}

.fw-bold {
    font-weight: 700 !important;
}


/* ==========================================================================
Height
========================================================================== */
.h-100  { height: 100% !important};
.h-90   { height: 90% !important};
.h-80   { height: 80% !important};
.h-70   { height: 70% !important};
.h-60   { height: 60% !important};
.h-50   { height: 50% !important};
.h-40   { height: 40% !important};
.h-30   { height: 30% !important};
.h-20   { height: 20% !important};
.h-10   { height: 10% !important};

.h-without-navbar {
    height: var(--screen-height);
    margin-top: var(--navbar-height) !important;
}

/* ==========================================================================
Width
========================================================================== */
.w-100  { width: 100% !important};
.w-90   { width: 90% !important};
.w-80   { width: 80% !important};
.w-70   { width: 70% !important};
.w-60   { width: 60% !important};
.w-50   { width: 50% !important};
.w-40   { width: 40% !important};
.w-30   { width: 30% !important};
.w-20   { width: 20% !important};
.w-10   { width: 10% !important};

/* ==========================================================================
Text color
========================================================================== */
.text-primary   { color : var(--primary) !important }
.text-second    { color : var(--second) !important }
.text-black     { color : var(--black) !important }
.text-white     { color : var(--white) !important }


/* ==========================================================================
Container
========================================================================== */
.container {
    
}
.box-shadox {
    box-shadow: var(--box-shadow);
}
/* ==========================================================================
Section Title
========================================================================== */
.section{
    padding: 80px 0 65px;
}
.section-header{
    color: #fff;
    margin-bottom: 50px;
    text-align: center;
    position: relative;
    .section-title{
    font-size: 30px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    position: relative;
    }

}

/* ==========================================================================
Buttons
========================================================================== */
.btn{
    font-size: 20
    px;
    padding: 15px 25px;
    border-radius: 50px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
    color: #fff;
    border: none;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    
}
.btn-second-fill{
    background-color: var(--second);
    border: 2px solid var(--second);
    color: var(--white);
    &:hover{
        background-color: var(--white);
        border: 2px solid var(--second);
        color: var(--second);
    }
}
.btn-second-outline{
    background-color: var(--white);
    border: 2px solid var(--second);
    color: var(--second);
    &:hover{
        background-color: var(--second);
        color: var(--white);
    }
}
.btn-white-fill {
    background-color: var(--white);
    border: 2px solid var(--white);
    color: var(--second);
}
.btn-shadow {
    box-shadow: 10px 10px 60px rgba(254, 130, 47, 0.16);
}
.btn-solution {
    max-width: 320px;
}
/* ==========================================================================
Social Iocns
========================================================================== */
.social-icons {
    margin-bottom:20px
}
.social-icons ul {
    margin:0;
    padding:0;
    list-style:none
}
.social-icons ul li {
    display:inline
}
.social-icons ul li a {
    display:inline-block;
    margin-left:5px;
    margin-right:5px;
    margin-bottom:15px;
    border-radius: 4px;
    border: 1px solid rgba(255, 254, 254, 0.07);
    line-height:40px;
    width:40px;
    height:40px;
    text-align:center;
    font-size:16px
}
.social-icons ul li a:hover {
    color:#fff
}
.facebook a {
    color:#4867AA
}
.facebook a:hover {
    background:#4867AA
}
.twitter a {
    color:#1DA1F2
}
.twitter a:hover {
    background:#1DA1F2
}
.google-plus a {
    color:#DD4D42
}
.google-plus a:hover {
    background:#DD4D42
}
.youtube a {
    color:#DF2926
}
.youtube a:hover {
    background:#DF2926
}
.linkedin a {
    color:#007BB6
}
.linkedin a:hover {
    background:#007BB6
}
.pinterest a {
    color:#BD081C
}
.pinterest a:hover {
    background:#BD081C
}
.dribbble a {
    color:#EA4C89
}
.dribbble a:hover {
    background:#EA4C89
}
.behance a {
    color:#0B7CFF
}
.behance a:hover {
    background:#0B7CFF
}

/* Preloader */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 9999999;
}
.loader{
    top: 50%;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
}
#loader-1:before, #loader-1:after{
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 7px solid transparent;
    border-top-color: var(--primary);
}

#loader-1:before{
    z-index: 100;
    animation: spin 2s infinite;
}

#loader-1:after{
    border: 7px solid #fafafa;
}

@keyframes spin{
    0%{
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    }

    100%{
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
    }
}

/* ========================================================================== 
Scroll To Up 
========================================================================== */
.back-to-top {
    display: none;
    position: fixed;
    bottom: 18px;
    right: 15px;
    width: 45px;
    height: 45px;
    line-height: 50px;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 8px 9px 0px rgba(96, 94, 94, 0.17);
    z-index:1000;
}

.back-to-top i {
    background: linear-gradient(to right, rgb(60,150,255) 0%, rgb(45,251,255) 100%);
    -webkit-background-clip: text;
    font-size: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

/* ========================================================================== 
Flex
========================================================================== */
.flex-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.flex-y-center {
    display: flex !important;
    align-items: center !important;
}

.flex-x-center {
    display: flex !important;
    justify-content: center !important;
}

.flex-col-center {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
}

.flex-col-y-center {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}

.flex-col-x-center {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}


/* ========================================================================== 
Others
========================================================================== */
.card {
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    border: none;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.link-underline {
    color: var(--black);
    padding: 5px 0px;
    position: relative;
    &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: var(--black);
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        transition: 0.3s;
    }
}

.link-underline:hover {
    color: var(--black) !important;
}

.link-underline:hover::after{
    width: 100%;
}