// XL Screen 
@media (min-width: 1200px) {
    .container {
        max-width: 1480px !important;
        padding: 0 4% !important;
    }

    .footer-content {
        .col-md-6:first-child {
            padding-right: 150px;
        }
    }
}

// Portrait tablet to landscape and desktop 
@media (min-width: 768px) and (max-width: 1024px) { 
    .section{
        padding: 60px 0 45px;
    }
    .section-header .section-title{
        font-size: 22px;
    }
    .block-title{
        font-size: 16px;
    }
    .home-section-0 .contents{
        padding: 90px 0 20px;
    }
    .navbar-expand-md .navbar-nav .nav-link{
        padding: 12px 5px;
        font-size: 13px;
        margin-left: 15px;
    }  
    .text-wrapper h3{
        font-size: 24px;
    }
    .back-to-top{
        bottom: 55px;
    }
}

/* only small tablets */
@media (min-width: 768px) and (max-width: 991px) {
    .h-without-navbar {
        height: auto;
    }
    .navbar-brand img{
        width: 120px;
    }
    .navbar .btn {
        font-size: 12px;
        padding: 15px;
    }
    .navbar-expand-md .navbar-nav .nav-link{
        padding: 8px 5px;
        margin-left: 5px;
    }
   .home-section-0 .contents{
        padding: 100px 0 20px;
    }
    .text-wrapper h3{
        font-size: 18px;
    }
    .widget{
        margin-top: 30px;
    }
    .back-to-top{
        bottom: 55px;
    }
}

@media only screen and (max-width: 767px) { 
    .navbar-brand img {
        width: 150px;
    }
    .btn {
        font-size:14px;
    }
    h4, .text-h4 {
        font-size: 18px;
    }
    .h-without-navbar {
        height: auto;
    }
    .image-content img {
            max-height: 80vh;
    }
    .section{
        padding: 60px 0 45px;
    }
    .col-xs-12 {
        margin-bottom: 30px;
    }
    .home-section-0 .contents{
        padding: 100px 0 20px;
    }
    .home-section-0 .contents h2{
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 15px;
    }
    .navbar{
        padding: 0;
    }
    .navbar-expand-md .navbar-brand, 
    .navbar-expand-md .navbar-toggler{
        margin: 6px 15px;
    }
    .navbar-expand-md .navbar-nav{
        margin-bottom: 10px;
    }
    .navbar-expand-md .navbar-nav .nav-link{
        padding: 10px 0px;
        text-align: left;
        margin-left: 20px;
        margin-right: 20px;
    }
    .section-header .section-title{
        font-size: 22px;
    }
    .text-wrapper h3{
        font-size: 18px;
    }
    .widget{
        margin-top: 30px;
    }
    .controls a{
        padding: 8px 16px;
    }
    .text-wrapper{
        margin-bottom: 15px;
    }
    .back-to-top{
        bottom: 55px;
    }

    .copyright {
        .row .col-xs-12:first-child {
            order: 2;
        }
        .row .col-xs-12:last-child {
            order: 1;
        }
    }
    
}

// Landscape phones and down 
@media (min-width: 320px) and (max-width: 480px) {
    h1, .text-h1 {
        font-size: 30px !important;
    }

    h2, .text-h2 {
        font-size: 30px !important;
    }

    .btn {
        font-size: 14px !important;
    }
    .section{
        padding: 60px 0 45px;
    }
    .section-header{
        margin-bottom: 30px;
    }
    .navbar{
        background-color: var(--white);
        padding: 0;
    }
    .navbar-expand-md .navbar-brand, 
    .navbar-expand-md .navbar-toggler{
        margin: 6px 15px;
    }
    .navbar-expand-md .navbar-nav{
        background-color: var(--white);
        margin-bottom: 10px;
        box-shadow: 0 10px 10px 0 rgb(0 0 0 / 20%);
    }
    .navbar-expand-md .navbar-nav .nav-link{
        background-color: var(--white);
        padding: 10px 0px;
        text-align: left;
        margin-left: 20px;
        margin-right: 20px;
    }
    .section-header .section-title{
        font-size: 20px;
        line-height: 30px;
    }
   .home-section-0 .contents{
        padding: 100px 0 20px;
    }
   .home-section-0 .contents .btn{
        margin-right: 5px;
        padding: 7px 20px;
        margin-top: 20px;
    }
    .text-wrapper h3{
        font-size: 18px;
    }
    .testimonial-item{
        padding: 10px;
    }
    .text-right{
        text-align: left!important;
    }
    .pricing-tables .pricing-table{
        margin-bottom: 30px;
    }
    .subscribe-form .sub-title h3{
        font-size: 20px;
    }
    .subscribe-form .form-wrapper .form-control{
        padding: 20px 16px;
        font-size: 14px;
    }
    .subscribe-form .form-wrapper .btn-search{
        width: 115px;
        font-size: 11px;
    }
    .copyright p{
        line-height: 26px;
    }
    .widget{
        margin-top: 30px;
    }
    .controls a{
        padding: 8px 16px;
        margin-bottom: 5px;
    }
    .back-to-top{
        bottom: 55px;
    }
}

