.text-content {
    padding-bottom: 0 !important;

    .container {
        padding: 0 10% !important;
    }
        
    h2, h3 {
        margin-top: 35px;
        font-size: 20px;
        font-weight: bold;
    }

    p {
        color: #302f30 !important;
        line-height: 30px !important;
        font-weight: 300 !important;
        font-size: 18px !important;
        text-align: justify;
        padding: 1rem 0px;

        strong {
            font-weight: 600;
        }
    }

    ul li{
        color: #302f30 !important;
        line-height: 30px !important;
        font-weight: 300 !important;
        font-size: 18px !important;
        list-style:inside !important;
        padding-left: 20px;

        strong {
            font-weight: bold !important;
        }
    }
}